header {
  background-color: #fefcf0;
  border-bottom: 1px solid #f1f0f0;
  padding: 15px 0px;
  .logos {
    img {
      height: 70px;
    }
    .divider {
      width: 1px;
      height: 70px;
      border-left: 1px solid rgb(223, 223, 223);
      margin: 0px 30px;
    }
    .logo {
    }
    .yunojuno-logo {
      height: 50px;
    }
  }
}
